import leftct from "react";
import { SubHeading } from "../../components";

import { images } from "../../constants";
import "./Calender.css";

const Calender = () => (
  <div className="app__aboutus section__padding app__wrapper" id="calender">
    <h1 className="app__calender-h1">Studio Schedule</h1>

    <div className="app__aboutus-content_about">
      <div className="p__opensans grid_left">Start of lessons</div>
      <div className="p__opensans grid_right ">September 9, 2024</div>
      <div className="p__opensans grid_left">Thanksgiving Week Vacation</div>
      <div className="p__opensans grid_right ">
        November 25 - November 29, 2024
      </div>
      <div className="p__opensans grid_left">Holiday Open House Dates</div>
      <div className="p__opensans grid_right ">
        Sunday December 8th, 2 - 4 pm (Tentative)
        <br /> Sunday December 15th, 2 - 4 pm (Tentative)
      </div>
      <div className="p__opensans grid_left">Holiday Vacation</div>
      <div className="p__opensans grid_right ">
        December 16 - January 3, 2025
      </div>
      {/* </div>
      <SubHeading title="2024" />
      <div className="app__aboutus-content_about"> */}
      <div className="p__opensans grid_left ">Lessons Resume</div>
      <div className="p__opensans grid_right ">January 6, 2025</div>
      <div className="p__opensans grid_left">Presidents' Week Break</div>
      <div className="p__opensans grid_right ">
        February 17 - February 21, 2025
      </div>
      <div className="p__opensans grid_left">Spring Break</div>
      <div className="p__opensans grid_right ">April 7 - April 11, 2025</div>
      <div className="p__opensans grid_left">Spring Recitals</div>
      <div className="p__opensans grid_right ">Dates TBD</div>
      <div className="p__opensans grid_left">
        Last Day of School Year Lessons
      </div>
      <div className="p__opensans grid_right ">May 23, 2025</div>
      <div className="p__opensans grid_left">Lessons by Appointment</div>
      <div className="p__opensans grid_right ">
        June, July, and August, 2025
      </div>
      {/* <button type="button" className="custom__button">Know More</button> */}
    </div>
    {/* <div className="secondrow">
      <div className="p__opensans">June, July, and August, 2024</div>
    </div> */}
  </div>
);

export default Calender;
