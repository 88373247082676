import bg from "../assets/bg.png";
import chef from "../assets/chef.JPG";
import logo from "../assets/logo.png";
import welcome from "../assets/welcome.jpg";
import keys from "../assets/keys.jpg";
import spoon from "../assets/spoon.png";

export default {
  bg,
  chef,
  logo,
  welcome,
  keys,
  spoon,
};
